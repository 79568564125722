<template>
    <div>Review</div>
</template>

<script>
export default {
    name: "Review"
}
</script>

<style scoped>

</style>
